@import "leaflet/dist/leaflet.css";

.App-header {
  display: flex;
  align-items: center;
  background-color: #282c34;
  padding: 10px 20px;
  color: white;
}

.header-logo {
  height: 50px; /* Adjust size as needed */
  width: auto;
  margin-right: 20px;
}

h1 {
  font-size: 2rem;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.App-content {
  padding: 20px;
  text-align: left;
  background-size: cover;
  height: calc(100vh - 120px);
}

.info-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Add spacing below the footer if needed */
body {
  margin: 0;
  padding: 0;
  background-color: #e6e6e6; /* Page background color */
}
